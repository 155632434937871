import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../../pages/Home/Home';
import Login from '../../pages/Login/Login';
import Register from '../../pages/Register/Register';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import Account from '../../pages/Account/Account';
import Users from '../../pages/Users/Users';
import { User } from '../User/User';
import TermsAndConditions from '../../pages/TermsAndConditions/TermsAndConditions';
import PostHistory from '../PostHistory/PostHistory';

const Main = () => {
    const [user, updateUser] = User();

    return (
        <Routes>
            <Route
                exact
                path='/'
                element={<Home user={user} updateUser={updateUser} />}
            />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/register' element={<Register />} />
            <Route exact path='/reset-password' element={<ResetPassword />} />
            <Route
                exact
                path='/terms-and-conditions'
                element={
                    <TermsAndConditions user={user} updateUser={updateUser} />
                }
            />
            <Route
                exact
                path='/account'
                element={<Account user={user} updateUser={updateUser} />}
            />
            <Route
                exact
                path='/users'
                element={<Users user={user} updateUser={updateUser} />}
            />
            <Route
                exact
                path='/account/history'
                element={<PostHistory user={user} updateUser={updateUser} />}
            />
        </Routes>
    );
};

export default Main;
