import React, { useEffect, useState, useCallback } from 'react';
import {
    TextField,
    InputLabel,
    Button,
    Grid,
    Box,
    Typography,
    Container,
    CircularProgress,
    Alert,
    AlertTitle,
    Stepper,
    Step,
    StepButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Card,
    CardContent,
    Tooltip,
    IconButton,
    Autocomplete,
    useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ReplayIcon from '@mui/icons-material/Replay';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TwitterIcon from '@mui/icons-material/Twitter';
import NotesIcon from '@mui/icons-material/Notes';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { usedPost } from '../User/User';
import { generatePost } from '../ServerRequests/ServerRequests';
import { TwitterShareButton, EmailShareButton, EmailIcon } from 'react-share';
import EmojiSelector from './EmojiSelector';
import PostLengthSelector from './PostLengthSelector';
import { postLengths } from './PostLengthSelector';
import { useDialog } from '../Dialog/DialogContext';
import { logout } from '../Firebase/Firebase';

const defaultValues = {
    style: null,
    postLength: null,
    noWebSearch: false,
    tone: 'positive',
    category: 'For Fun',
    prompt: '',
    maxLength: 250,
};

const steps = ['Style', 'Category', 'Tone & Length', 'Prompt', 'Post'];

const currentDate = new Date();
const options = { month: 'short', day: 'numeric' };
const formattedDate = currentDate.toLocaleDateString(undefined, options);

const PostForm = (props) => {
    let initialValues = { ...defaultValues };
    const searchParams = useSearchParams()[0];
    for (const [key, val] of searchParams.entries()) {
        if (key in initialValues) {
            if (key === 'postLength') {
                initialValues[key] = parseInt(val);
            } else {
                initialValues[key] = val;
            }
        }
    }
    const navigate = useNavigate();
    const [values, setValues] = useState(initialValues);
    const [plannedStep, setPlannedStep] = useState(null);
    const [coppiedText, setCoppiedText] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [errorMsg, setErrorMsg] = useState(<></>);
    const [generating, setGenerating] = useState(false);
    const [data, setData] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [charCount, setCharCount] = useState(0);
    let user = props.user;
    let updateUser = props.updateUser;
    let setHomeAction = props.setHomeAction;
    let setParamsFunction = props.setParamsFunction;

    const {
        dialogAction,
        dialogTitle,
        dialogText,
        dialogOpen,
        setDialogAction,
        setDialogTitle,
        setDialogText,
        setDialogOpen,
    } = useDialog();

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const isFirstStep = () => {
        return activeStep === 0;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = async () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        setButtonDisabled(true);
        await new Promise((r) => setTimeout(r, 100));
        setButtonDisabled(false);
    };

    const handleOutOfPosts = useCallback(() => {
        setDialogAction('out-of-posts');
        setDialogTitle('Out of Posts');
        setDialogText(
            <DialogContentText>
                You're out of posts for the day.
                <br />
                Please come back tomorrow!
                <br />
                Free tier allows 10 posts per day.
            </DialogContentText>
        );
        setDialogOpen(true);
    }, [setDialogAction, setDialogTitle, setDialogText, setDialogOpen]);

    const handleStep = (step) => () => {
        if (
            plannedStep === null &&
            ((activeStep >= 4 && step < 4) || generating)
        ) {
            setPlannedStep(step);
            setDialogAction('step');
            setDialogTitle('Change Step');
            setDialogText(
                <DialogContentText>
                    Are you sure you want to change to step {step + 1}?
                    <br />
                    <strong>
                        <br />
                        You will lose the post that has been generated!
                    </strong>
                </DialogContentText>
            );
            setDialogOpen(true);
            return;
        } else if (plannedStep != null) {
            setActiveStep(plannedStep);
        } else {
            setActiveStep(step);
        }
        setCoppiedText(false);
        setPlannedStep(null);
        handleClose();
    };

    const handleBack = (regenerate = false) => {
        setCoppiedText(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setErrorState(false);
        setErrorMsg(<></>);
        if (!regenerate) {
            setGenerating(false);
        }
        handleClose();
    };

    const handleSignOut = () => {
        logout();
        window.location.reload();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        setData({});
        setCharCount(0);
        setCoppiedText(false);
        setValues(defaultValues);
        setErrorState(false);
        setErrorMsg(<></>);
        setGenerating(false);
        handleClose();
    };

    const handleError = (errorMessage) => {
        setErrorMsg(
            <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
            </Alert>
        );
        setErrorState(true);
    };

    const handleChange = (e) => {
        let { value, name } = e.target;
        if (name.startsWith('postLength')) {
            name = 'postLength';
        }

        if (name === 'style') {
            if (values.postLength === null) {
                setValues({
                    ...values,
                    postLength: postLengths[value].medium,
                    [name]: value,
                });
                return;
            }
        }

        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleButtonClick = (option) => {
        handleChange({ target: { name: 'style', value: option } });
        handleNext();
    };

    const tweetCharacterLimit = 280;
    const [charLimitErrorMessage, setCharLimitErrorMessage] = useState('');

    const enforceCharacterLimit = (value) => {
        if (value.length <= tweetCharacterLimit) {
            return value;
        } else {
            return value.substring(0, tweetCharacterLimit);
        }
    };

    const handleDataChange = (e) => {
        let { value, name } = e.target;

        const limitedValue = enforceCharacterLimit(value);

        if (limitedValue !== value) {
            setCharLimitErrorMessage(
                `Oops! Tweets are limited to ${tweetCharacterLimit} characters.`
            );
        } else {
            setCharLimitErrorMessage('');
        }

        if (name === 'text' && values.style === 'tweet') {
            value = limitedValue;
        }

        setData({
            ...data,
            [name]: value,
        });
        setCharCount(value.length);
    };

    const formatPost = (post) => {
        const regex = /(#+[a-zA-Z0-9(_)]{1,})/g;
        const substringList = post.split(regex);
        return (
            <>
                {substringList.map((str) => {
                    if (str.includes('#')) {
                        return (
                            <p style={{ color: '#1D9BF0', display: 'inline' }}>
                                {str}
                            </p>
                        );
                    } else {
                        return <>{str}</>;
                    }
                })}
            </>
        );
    };

    const getParams = useCallback(() => {
        let paramStr = '';
        for (const property in values) {
            if (paramStr !== '') {
                paramStr += '&';
            }
            paramStr += `${property}=${values[property]}`;
        }
        paramStr += `&activeStep=${activeStep}`;
        return paramStr;
    }, [values, activeStep]);

    const handleRegenerate = () => {
        if (!user.uid) {
            setErrorMsg(
                <Alert severity='error'>
                    <AlertTitle>Error</AlertTitle>
                    User is not logged in —{' '}
                    <strong>
                        please <Link to={`/login?${getParams()}`}>sign in</Link>{' '}
                        to continue!
                    </strong>
                </Alert>
            );
            setErrorState(true);
            return;
        }
        if (user.posts < 1) {
            setErrorMsg(
                <Alert severity='warning'>
                    <AlertTitle>Warning</AlertTitle>
                    Not enough posts —{' '}
                    <strong>
                        please upgrade account or wait until tomorrow to
                        continue!
                    </strong>
                </Alert>
            );
            setErrorState(true);
            return;
        }
        setDialogAction('regenerate');
        setDialogTitle('Regenerate Post');
        setDialogText(
            <DialogContentText>
                Are you sure you want to regenerate this post?
                <br />
                <strong>It will use 1 post!</strong>
                <br />
                You currently have {user.posts} posts.
                <br />
                Free tier allows 10 posts per day.
            </DialogContentText>
        );
        setDialogOpen(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!user.uid) {
            setErrorMsg(
                <Alert severity='warning'>
                    <AlertTitle>Oops you havent logged in!</AlertTitle>
                    <strong>
                        Please <Link to={`/login?${getParams()}`}>sign in</Link>{' '}
                        to continue!
                    </strong>
                </Alert>
            );
            setErrorState(true);
            return;
        }
        if (user.posts < 1) {
            setErrorMsg(
                <Alert severity='warning'>
                    <AlertTitle>Warning</AlertTitle>
                    Not enough posts —{' '}
                    <strong>
                        please upgrade account or wait until tomorrow to
                        continue!
                    </strong>
                </Alert>
            );
            setErrorState(true);
            return;
        }
        setDialogAction('generate');
        setDialogTitle('Generate Post');
        setDialogText(
            <DialogContentText>
                Are you sure you want to generate this post?
                <br />
                <strong>It will use 1 post!</strong>
                <br />
                You currently have {user.posts} posts.
                <br />
                Free tier allows 10 posts per day.
            </DialogContentText>
        );
        setDialogOpen(true);
    };

    const handleResetDialog = useCallback(
        (event) => {
            event.preventDefault();
            if (activeStep !== 0) {
                setDialogAction('reset');
                setDialogTitle('Reset');
                setDialogText(
                    <DialogContentText>
                        Are you sure you want to reset?
                        <br />
                        <strong>This will lose all progress...</strong>
                        {activeStep >= 4 && (
                            <strong>
                                <br />
                                You will lose the post that has been generated!
                            </strong>
                        )}
                    </DialogContentText>
                );
                setDialogOpen(true);
            }
        },
        [
            setDialogAction,
            setDialogTitle,
            setDialogText,
            setDialogOpen,
            activeStep,
        ]
    );

    const handleBackDialog = (event) => {
        event.preventDefault();
        if (activeStep !== 4) {
            handleBack();
            return;
        }
        setDialogAction('back');
        setDialogTitle('Back');
        setDialogText(
            <DialogContentText>
                Are you sure you want to go back?
                <br />
                <strong>You will lose the post that has been generated!</strong>
            </DialogContentText>
        );
        setDialogOpen(true);
    };

    const handleClose = () => {
        setErrorState(false);
        setErrorMsg(<></>);
        setDialogOpen(false);
    };

    const handleGenerate = async () => {
        handleClose();
        setGenerating(true);
        // Check if regenerating
        let regenerating = false;
        if (activeStep === 4) {
            regenerating = true;
        }
        if (values.prompt === 'demo prompt') {
            await new Promise((r) => setTimeout(r, 2000));
            const res = {
                text: 'This is a demo post! Try to edit me or Post me!',
            };
            setData(res);
            setCharCount(res.text.length);
            setGenerating(false);
            handleNext();
            if (regenerating) {
                handleBack();
            }
            return;
        }
        generatePost(
            values.style,
            values.tone,
            values.category,
            values.postLength,
            values.prompt,
            values.noWebSearch,
            handleError,
            user.uid
        ).then((res) => {
            setGenerating(false);
            if (res.success && res.choices.length > 0) {
                for (let i = 0; i < res.choices.length; i++) {
                    let text = res.choices[i].message.content.trim();
                    text = text.replace(/['"]+/g, '');
                    res.choices[i].text = text;
                }
                setData(res.choices[0]);
                setCharCount(res.choices[0].text.length);
                usedPost(user, updateUser);
                if (!regenerating) {
                    handleNext();
                }
                // setGenerating(true);
            }
        });
        // Disable generate image untill we have finalized implementation
        // generateImage(values.prompt, 3, handleError).then((res) => {
        // TODO: Do something with images...
        // setGeneratingImage(false);
        //     if (res.success && res.choices.length > 0) {
        //         console.log(res.choices);
        //     }
        // });
    };

    useEffect(() => {
        let params = searchParams.toString();
        if (params.length > 0)
            setActiveStep(parseInt(params.charAt(params.length - 1)));
        if (!searchParams.keys().next().done) {
            navigate('/');
        }
        if (
            user.posts != null &&
            user.nextPostRefresh != null &&
            parseInt(user.posts) === 0 &&
            activeStep < 4
        ) {
            handleOutOfPosts();
        }
    }, [navigate, searchParams, user, activeStep, handleOutOfPosts]);

    useEffect(() => {
        setParamsFunction(() => getParams);
        setHomeAction(() => handleResetDialog);
    }, [setParamsFunction, setHomeAction, getParams, handleResetDialog]);

    const isMobile = useMediaQuery('(max-width:520px)');

    return (
        <Container component='main' maxWidth='xm'>
            <Box
                display='flex'
                alignItems='center'
                minHeight='100vh'
                flexDirection='column'
            >
                <Typography component='p' variant='h5' sx={{ mb: 2 }}>
                    Create A Post
                </Typography>
                <Box
                    maxWidth='80vh'
                    sx={{ width: isMobile ? '100%' : '80%' }}
                    component='form'
                    onSubmit={handleSubmit}
                >
                    {errorState && errorMsg}
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                if (dialogAction === 'generate') {
                                    handleGenerate();
                                } else if (dialogAction === 'reset') {
                                    handleReset();
                                } else if (dialogAction === 'back') {
                                    handleBack();
                                } else if (dialogAction === 'out-of-posts') {
                                    handleClose();
                                } else if (dialogAction === 'sign-out') {
                                    handleSignOut();
                                }
                            }
                        }}
                    >
                        <DialogTitle>{dialogTitle}</DialogTitle>
                        <DialogContent>{dialogText}</DialogContent>
                        <DialogActions>
                            {dialogAction === 'out-of-posts' ? (
                                <Button onClick={handleClose}>Okay</Button>
                            ) : (
                                <>
                                    <Button onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    {dialogAction === 'generate' ? (
                                        <Button
                                            onClick={handleGenerate}
                                            autoFocus
                                        >
                                            Generate
                                        </Button>
                                    ) : dialogAction === 'regenerate' ? (
                                        <Button
                                            onClick={handleGenerate}
                                            autoFocus
                                        >
                                            Regenerate
                                        </Button>
                                    ) : dialogAction === 'reset' ? (
                                        <Button onClick={handleReset} autoFocus>
                                            Reset
                                        </Button>
                                    ) : dialogAction === 'step' ? (
                                        <Button
                                            onClick={handleStep(plannedStep)}
                                            autoFocus
                                        >
                                            Change Step
                                        </Button>
                                    ) : dialogAction === 'sign-out' ? (
                                        <Button
                                            onClick={handleSignOut}
                                            autoFocus
                                        >
                                            Sign Out
                                        </Button>
                                    ) : (
                                        dialogAction === 'back' && (
                                            <Button
                                                onClick={handleBack}
                                                autoFocus
                                            >
                                                Back
                                            </Button>
                                        )
                                    )}
                                </>
                            )}
                        </DialogActions>
                    </Dialog>
                    <Stepper
                        activeStep={activeStep}
                        sx={{
                            mb: 2,
                            mt: 2,
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            padding: '0',
                            '& .MuiStepLabel-label': {
                                '& .MuiStepLabel-iconContainer': {
                                    paddingRight: '8px',
                                },
                            },
                            '& .MuiStepConnector-root': {
                                margin: '0 4px',
                            },
                        }}
                    >
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton
                                    color='inherit'
                                    onClick={handleStep(index)}
                                    style={{
                                        flex: '0 0 auto',
                                    }}
                                >
                                    {!isMobile && label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>

                    <Box
                        sx={{
                            mx: 'auto',
                            width: '100%',
                            '@media (max-width: 430px)': {
                                width: isMobile ? '100%' : '80%',
                            },
                        }}
                    >
                        {activeStep === 0 ? (
                            <>
                                <InputLabel
                                    style={{
                                        marginBottom: isMobile
                                            ? '20px'
                                            : '30px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Style
                                </InputLabel>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: isMobile
                                            ? 'column'
                                            : 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-evenly',
                                        marginBottom: '10px',
                                        minHeight: isMobile ? '120px' : '200px',
                                    }}
                                >
                                    <Tooltip title='Tweet'>
                                        <IconButton
                                            color='primary'
                                            aria-label='Tweet'
                                            onClick={() =>
                                                handleButtonClick('tweet')
                                            }
                                        >
                                            <TwitterIcon
                                                sx={{
                                                    fontSize: isMobile
                                                        ? '100px'
                                                        : '170px',
                                                    '&:hover': {
                                                        fontSize: isMobile
                                                            ? '75px'
                                                            : '125px',
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Blog'>
                                        <IconButton
                                            color='primary'
                                            aria-label='Blog'
                                            onClick={() =>
                                                handleButtonClick('blog')
                                            }
                                            style={{
                                                marginTop: isMobile
                                                    ? '10px'
                                                    : '0',
                                            }}
                                        >
                                            <NotesIcon
                                                sx={{
                                                    fontSize: isMobile
                                                        ? '100px'
                                                        : '170px',
                                                    '&:hover': {
                                                        fontSize: isMobile
                                                            ? '75px'
                                                            : '125px',
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </>
                        ) : activeStep === 1 && !generating ? (
                            <div
                                style={{
                                    minHeight: isMobile ? 225 : 275,
                                }}
                            >
                                <InputLabel
                                    style={{
                                        marginBottom: isMobile ? '5px' : '10px',
                                    }}
                                >
                                    Category
                                </InputLabel>
                                <Autocomplete
                                    id='category-input'
                                    name='category'
                                    value={values.category}
                                    onChange={(event, newValue) => {
                                        handleChange({
                                            target: {
                                                name: 'category',
                                                value: newValue,
                                            },
                                        });
                                    }}
                                    options={[
                                        'For Fun',
                                        'Marketing',
                                        'Technology',
                                        'Business',
                                        'Food',
                                        'Entertainment',
                                        'Sports',
                                        'Health & Wellness',
                                        'Travel',
                                        'Personal Development',
                                        'Other',
                                    ]}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder='' />
                                    )}
                                    fullWidth={true}
                                />
                            </div>
                        ) : activeStep === 2 && !generating ? (
                            <div
                                style={{
                                    minHeight: 275,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Grid container spacing={3}>
                                    {/* Single column layout for screens less than 600 pixels wide */}
                                    <Grid item xs={12}>
                                        <InputLabel>Tone</InputLabel>
                                        <EmojiSelector
                                            handleChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel
                                            style={{
                                                marginTop: '40px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Length
                                        </InputLabel>
                                        <PostLengthSelector
                                            style={values.style}
                                            currentLength={values.postLength}
                                            handleChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ) : activeStep === 3 && !generating ? (
                            <div style={{ minHeight: 275, padding: '16px' }}>
                                <InputLabel
                                    style={{
                                        fontSize: '0.875rem',
                                        marginBottom: '8px',
                                    }}
                                >
                                    Prompt
                                </InputLabel>
                                <TextField
                                    size='small'
                                    id='content-input'
                                    name='prompt'
                                    required={true}
                                    variant='filled'
                                    color='primary'
                                    value={values.prompt ? values.prompt : ''}
                                    rows={8}
                                    multiline={true}
                                    fullWidth={true}
                                    inputProps={{
                                        style: { fontSize: '0.875rem' },
                                    }}
                                    InputLabelProps={{
                                        style: { fontSize: '0.875rem' },
                                    }}
                                    onChange={handleChange}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            handleSubmit(event);
                                        }
                                    }}
                                />
                            </div>
                        ) : (activeStep === 3 || activeStep === 4) &&
                          generating ? (
                            <Box
                                display='flex'
                                alignItems='center'
                                flexDirection='column'
                            >
                                <Typography component='h1' variant='h6'>
                                    Generating post...
                                </Typography>
                                <CircularProgress sx={{ mt: 2 }} />
                            </Box>
                        ) : (
                            isLastStep() && (
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    flexDirection='column'
                                >
                                    {coppiedText && (
                                        <Alert
                                            severity='success'
                                            sx={{ mb: 2 }}
                                        >
                                            Text copied to clipboard!
                                        </Alert>
                                    )}
                                    <div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    alignItems='center'
                                                >
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='h6'
                                                            align='left'
                                                        >
                                                            Generated Post
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        container
                                                        justifyContent='flex-end'
                                                    >
                                                        {!editMode ? (
                                                            <Tooltip title='edit'>
                                                                <Button
                                                                    onClick={() =>
                                                                        setEditMode(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </Button>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title='done'>
                                                                <Button
                                                                    onClick={() =>
                                                                        setEditMode(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    <DoneIcon />
                                                                </Button>
                                                            </Tooltip>
                                                        )}
                                                        <Tooltip title='regenerate'>
                                                            <Button
                                                                onClick={
                                                                    handleRegenerate
                                                                }
                                                            >
                                                                <ReplayIcon />
                                                            </Button>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {values.style === 'tweet' ? (
                                            <>
                                                {editMode ? (
                                                    <>
                                                        <Card
                                                            sx={{
                                                                width: '500px',
                                                                maxWidth:
                                                                    '100%',
                                                                height: 'auto',
                                                                overflow:
                                                                    'hidden',
                                                                overflowY:
                                                                    'auto',
                                                                backgroundColor:
                                                                    '#fff',
                                                                borderRadius:
                                                                    '15px',
                                                                position:
                                                                    'relative',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                boxSizing:
                                                                    'border-box',
                                                                '@media (max-width: 520px)':
                                                                    {
                                                                        width: '100%',
                                                                        borderRadius: 0,
                                                                    },
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        marginBottom:
                                                                            '5px',
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            display:
                                                                                'inline-block',
                                                                            whiteSpace:
                                                                                'pre-line',
                                                                        }}
                                                                        size='small'
                                                                        id='content-output'
                                                                        name='text'
                                                                        InputProps={{
                                                                            readOnly: false,
                                                                        }}
                                                                        variant='filled'
                                                                        color='primary'
                                                                        value={
                                                                            data.text
                                                                        }
                                                                        rows={
                                                                            10
                                                                        }
                                                                        multiline={
                                                                            true
                                                                        }
                                                                        fullWidth={
                                                                            true
                                                                        }
                                                                        onChange={
                                                                            handleDataChange
                                                                        }
                                                                    />
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                        {charLimitErrorMessage && (
                                                            <Typography
                                                                sx={{
                                                                    marginTop:
                                                                        '8px',
                                                                }}
                                                                color='error'
                                                            >
                                                                {
                                                                    charLimitErrorMessage
                                                                }
                                                            </Typography>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Card
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: '100%',
                                                            height: 'auto',
                                                            overflow: 'hidden',
                                                            overflowY: 'auto',
                                                            backgroundColor:
                                                                '#fff',
                                                            borderRadius:
                                                                '15px',
                                                            position:
                                                                'relative',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            boxSizing:
                                                                'border-box',
                                                            '@media (max-width: 520px)':
                                                                {
                                                                    borderRadius: 0,
                                                                },
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    marginBottom:
                                                                        '5px',
                                                                }}
                                                            >
                                                                <AccountCircleIcon
                                                                    style={{
                                                                        color: '#919697',
                                                                        fontSize:
                                                                            '45px',
                                                                    }}
                                                                />
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 700,
                                                                        color: '#000',
                                                                        marginLeft:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    {user.name}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 500,
                                                                        color: '#919697',
                                                                        marginLeft:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    @{user.name}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 500,
                                                                        color: '#919697',
                                                                        marginLeft:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    ·{' '}
                                                                    {
                                                                        formattedDate
                                                                    }
                                                                </Typography>
                                                            </Box>

                                                            <Typography
                                                                sx={{
                                                                    display:
                                                                        'inline-block',
                                                                    whiteSpace:
                                                                        'pre-line',
                                                                }}
                                                            >
                                                                {formatPost(
                                                                    data.text
                                                                )}
                                                            </Typography>

                                                            <Box
                                                                className='bottom-row'
                                                                sx={{
                                                                    width: '100%',
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    justifyContent:
                                                                        'space-between',
                                                                    alignItems:
                                                                        'flex-end',
                                                                    marginTop:
                                                                        '10px',
                                                                    '@media (max-width: 520px)':
                                                                        {
                                                                            flexDirection:
                                                                                'column',
                                                                            alignItems:
                                                                                'center',
                                                                        },
                                                                }}
                                                            >
                                                                <Box
                                                                    className='tweet-info-counts'
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        marginLeft:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    <div
                                                                        class='comments'
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            marginRight:
                                                                                '20px',
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            class='feather feather-message-circle sc-dnqmqq jxshSx'
                                                                            style={{
                                                                                color: '#657786',
                                                                                marginRight:
                                                                                    '10px',
                                                                            }}
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='20'
                                                                            height='20'
                                                                            viewBox='0 0 24 24'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            stroke-width='2'
                                                                            stroke-linecap='round'
                                                                            stroke-linejoin='round'
                                                                            aria-hidden='true'
                                                                        >
                                                                            <path d='M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z'></path>
                                                                        </svg>
                                                                        <div class='comment-count'>
                                                                            33
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        class='retweets'
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            marginRight:
                                                                                '20px',
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            class='feather feather-repeat sc-dnqmqq jxshSx'
                                                                            style={{
                                                                                color: '#657786',
                                                                                marginRight:
                                                                                    '10px',
                                                                            }}
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='20'
                                                                            height='20'
                                                                            viewBox='0 0 24 24'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            stroke-width='2'
                                                                            stroke-linecap='round'
                                                                            stroke-linejoin='round'
                                                                            aria-hidden='true'
                                                                        >
                                                                            <polyline points='17 1 21 5 17 9'></polyline>
                                                                            <path d='M3 11V9a4 4 0 0 1 4-4h14'></path>
                                                                            <polyline points='7 23 3 19 7 15'></polyline>
                                                                            <path d='M21 13v2a4 4 0 0 1-4 4H3'></path>
                                                                        </svg>
                                                                        <div class='retweet-count'>
                                                                            397
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        class='likes'
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            marginRight:
                                                                                '20px',
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            class='feather feather-heart sc-dnqmqq jxshSx'
                                                                            style={{
                                                                                color: '#657786',
                                                                                marginRight:
                                                                                    '10px',
                                                                            }}
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='20'
                                                                            height='20'
                                                                            viewBox='0 0 24 24'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            stroke-width='2'
                                                                            stroke-linecap='round'
                                                                            stroke-linejoin='round'
                                                                            aria-hidden='true'
                                                                        >
                                                                            <path d='M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z'></path>
                                                                        </svg>
                                                                        <div class='likes-count'>
                                                                            2.6k
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        class='message'
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            marginRight:
                                                                                '20px',
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            class='feather feather-send sc-dnqmqq jxshSx'
                                                                            style={{
                                                                                color: '#657786',
                                                                                marginRight:
                                                                                    '10px',
                                                                            }}
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='20'
                                                                            height='20'
                                                                            viewBox='0 0 24 24'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            stroke-width='2'
                                                                            stroke-linecap='round'
                                                                            stroke-linejoin='round'
                                                                            aria-hidden='true'
                                                                        >
                                                                            <line
                                                                                x1='22'
                                                                                y1='2'
                                                                                x2='11'
                                                                                y2='13'
                                                                            ></line>
                                                                            <polygon points='22 2 15 22 11 13 2 9 22 2'></polygon>
                                                                        </svg>
                                                                    </div>
                                                                </Box>
                                                                <Tooltip title='Post to Twitter'>
                                                                    <div>
                                                                        <TwitterShareButton
                                                                            url={
                                                                                '\r'
                                                                            }
                                                                            title={
                                                                                data.text
                                                                            }
                                                                            via='postai'
                                                                        >
                                                                            <Button
                                                                                variant='text'
                                                                                sx={{
                                                                                    backgroundColor:
                                                                                        '#1DA1F1',
                                                                                    color: '#fff',
                                                                                    borderRadius: 5,
                                                                                    textTransform:
                                                                                        'none',
                                                                                    fontWeight: 550,
                                                                                    fontSize: 16,
                                                                                    padding:
                                                                                        '8px 16px',
                                                                                    '&:hover':
                                                                                        {
                                                                                            backgroundColor:
                                                                                                '#B3D9FF',
                                                                                        },
                                                                                    '@media (max-width: 520px)':
                                                                                        {
                                                                                            padding:
                                                                                                '8px 100px',
                                                                                            marginTop:
                                                                                                '20px',
                                                                                            alignItems:
                                                                                                'center',
                                                                                            justifyContent:
                                                                                                'center',
                                                                                        },
                                                                                }}
                                                                                url={
                                                                                    '\r'
                                                                                }
                                                                                title={
                                                                                    data.text
                                                                                }
                                                                                via='postai'
                                                                            >
                                                                                Tweet
                                                                            </Button>
                                                                        </TwitterShareButton>
                                                                    </div>
                                                                </Tooltip>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {editMode ? (
                                                    <>
                                                        <Card
                                                            sx={{
                                                                width: '500px',
                                                                height: 280,
                                                                overflow:
                                                                    'hidden',
                                                                overflowY:
                                                                    'auto',
                                                                backgroundColor:
                                                                    '#fff',
                                                                borderRadius:
                                                                    '15px',
                                                                position:
                                                                    'relative',
                                                                '@media (max-width: 520px)':
                                                                    {
                                                                        width: '100%',
                                                                        borderRadius: 0,
                                                                    },
                                                                
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        marginBottom:
                                                                            '5px',
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        sx={{
                                                                            display:
                                                                                'inline-block',
                                                                            whiteSpace:
                                                                                'pre-line',
                                                                        }}
                                                                        size='small'
                                                                        id='content-output'
                                                                        name='text'
                                                                        InputProps={{
                                                                            readOnly:
                                                                                !editMode,
                                                                        }}
                                                                        variant='filled'
                                                                        color='primary'
                                                                        value={
                                                                            data.text
                                                                        }
                                                                        rows={
                                                                            10
                                                                        }
                                                                        multiline={
                                                                            true
                                                                        }
                                                                        fullWidth={
                                                                            true
                                                                        }
                                                                        onChange={
                                                                            handleDataChange
                                                                        }
                                                                    />
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    </>
                                                ) : (
                                                    <Card
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: '100%',
                                                            height: 280,
                                                            overflow: 'hidden',
                                                            overflowY: 'auto',
                                                            '@media (max-width: 520px)': {
                                                                width: '100%',
                                                                borderRadius: 0,
                                                            },
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                variant='body'
                                                                sx={{
                                                                    display:
                                                                        'inline-block',
                                                                    whiteSpace:
                                                                        'pre-line',
                                                                    margin: '1rem',
                                                                    padding:
                                                                        '1rem',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius:
                                                                        '4px',
                                                                    backgroundColor:
                                                                        '#f9f9f9',
                                                                }}
                                                            >
                                                                {data.text
                                                                    .split('\n')
                                                                    .map(
                                                                        (
                                                                            line,
                                                                            index
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {index ===
                                                                                0 ? (
                                                                                    <>
                                                                                        <Typography
                                                                                            variant='h6'
                                                                                            component='h2'
                                                                                        >
                                                                                            {line.replace(
                                                                                                /^Title: /,
                                                                                                ''
                                                                                            )}
                                                                                        </Typography>
                                                                                        <Box
                                                                                            sx={{
                                                                                                display:
                                                                                                    'flex',
                                                                                                alignItems:
                                                                                                    'center',
                                                                                                marginBottom:
                                                                                                    '5px',
                                                                                                padding:
                                                                                                    '10px 0px',
                                                                                            }}
                                                                                        >
                                                                                            <AccountCircleIcon
                                                                                                style={{
                                                                                                    color: '#919697',
                                                                                                    fontSize:
                                                                                                        '25px',
                                                                                                }}
                                                                                            />
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    fontWeight: 600,
                                                                                                    color: '#000',
                                                                                                    marginLeft:
                                                                                                        '10px',
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    user.name
                                                                                                }
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    fontWeight: 500,
                                                                                                    color: '#919697',
                                                                                                    marginLeft:
                                                                                                        '10px',
                                                                                                }}
                                                                                            >
                                                                                                5h
                                                                                                ago
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </>
                                                                                ) : (
                                                                                    <Typography
                                                                                        variant='body'
                                                                                        component='p'
                                                                                    >
                                                                                        {
                                                                                            line
                                                                                        }
                                                                                    </Typography>
                                                                                )}
                                                                            </React.Fragment>
                                                                        )
                                                                    )}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <Grid container alignItems='center'>
                                        {window.isSecureContext && (
                                            <Grid item xs={1} sx={{ mt: 1 }}>
                                                <Tooltip title='Copy to Clipboard'>
                                                    <IconButton
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                data.text
                                                            );
                                                            setCoppiedText(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <FileCopyIcon
                                                            sx={{
                                                                height: 25,
                                                                width: 25,
                                                            }}
                                                            color='primary'
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            xs={1}
                                            sx={{
                                                mt: 1.5,
                                                ml: isMobile ? 2 : 0,
                                            }}
                                        >
                                            <Tooltip title='Share in Email'>
                                                <div>
                                                    <EmailShareButton
                                                        subject='Checkout this post I generated using PostAI'
                                                        body={data.text}
                                                    >
                                                        <EmailIcon
                                                            size={30}
                                                            round
                                                        />
                                                    </EmailShareButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            sm={10}
                                            sx={{
                                                mt: 1.5,
                                                ml: isMobile ? 2 : 0,
                                            }}
                                        >
                                            <Box
                                                display='flex'
                                                marginTop={
                                                    isMobile ? '10px' : '0'
                                                }
                                            >
                                                {values.style === 'tweet' ? (
                                                    <p
                                                        style={{
                                                            color:
                                                                charCount <= 280
                                                                    ? 'grey'
                                                                    : 'red',
                                                            margin: '0',
                                                        }}
                                                    >
                                                        {charCount}/{280}
                                                    </p>
                                                ) : (
                                                    <p style={{ margin: '0' }}>
                                                        {charCount}
                                                    </p>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                        )}
                    </Box>
                    {!generating && !isFirstStep() && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                pt: 2,
                            }}
                        >
                            <Button
                                color='inherit'
                                disabled={activeStep === 0}
                                onClick={handleBackDialog}
                                sx={{ ml: 1 }}
                            >
                                Back
                            </Button>
                            <Button
                                disabled={
                                    buttonDisabled ||
                                    (values === defaultValues &&
                                        activeStep === 0)
                                }
                                onClick={handleResetDialog}
                                sx={{ mr: 1 }}
                            >
                                Reset
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {activeStep === 3 ? (
                                <Button
                                    disabled={buttonDisabled}
                                    type='submit'
                                    sx={{ mr: 1 }}
                                >
                                    Generate
                                </Button>
                            ) : (
                                !isLastStep() && (
                                    <Button
                                        disabled={buttonDisabled}
                                        onClick={handleNext}
                                        sx={{ mr: 1 }}
                                    >
                                        Next
                                    </Button>
                                )
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default PostForm;
