import React from 'react';
import './App.css';
import Main from '../Main/Main';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { DialogProvider } from '../Dialog/DialogContext';

const themeLight = createTheme({
    palette: {
        background: {
            default: '#FDFAFA',
        },
        primary: {
            main: '#619b8a',
        },
        secondary: {
            main: '#233d4d',
        },
        google: {
            main: '#FFFFFF',
            dark: '#CECECE',
        },
        twitter: {
            main: '#00ACEE',
            dark: '#CECECE',
        },
        header: {
            main: '#31572c',
        },
    },
});

function App() {
    return (
        <div className='App'>
            <DialogProvider>
                <ThemeProvider theme={themeLight}>
                    <CssBaseline />
                    <Main />
                </ThemeProvider>
            </DialogProvider>
        </div>
    );
}

export default App;
