import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

const useStyles = makeStyles(() => ({
    cookies: {
        padding: '40px 10px',
        backgroundColor: '#555',
        color: '#FFFFFF',
        position: 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
    },
    cookiesContentWrapper: {
        textAlign: 'center',
    },
    cookiesButton: {
        backgroundColor: '#FFFFFF!important',
    },
}));

const Cookies = ({ cookiesDisplay, setCookiesDisplay }) => {
    const { cookies, cookiesContentWrapper, cookiesButton } = useStyles();

    const setCookie = (expiry) => {
        const date = new Date();
        date.setTime(date.getTime() + expiry * 24 * 60 * 60);
        let expires = ';expires=' + date.toUTCString();
        document.cookie = 'accepted=true' + expires + ';path=/';
        setCookiesDisplay(false);
    };

    const getCookie = () => {
        let enabled = 'accepted=';
        let spli = document.cookie.split(';');
        for (var j = 0; j < spli.length; j++) {
            let char = spli[j];
            while (char.charAt(0) === ' ') {
                char = char.substring(1);
            }
            if (char.indexOf(enabled) === 0) {
                return char.substring(enabled.length, char.length);
            }
        }
        return false;
    };

    const handleSubmit = () => {
        // Set cookie for 1 day
        setCookie(1);
    };

    useEffect(() => {
        const checkCookie = () => {
            var accepted = getCookie();
            // checking whether accepted is null or not
            if (accepted) {
                setCookiesDisplay(false);
            }
            //if accepted is null
            else {
                setCookiesDisplay(true);
            }
        };
        checkCookie();
    });

    return (
        <>
            {cookiesDisplay && (
                <div className={cookies}>
                    <div className={cookiesContentWrapper}>
                        <p>
                            This site uses cookies. By continuing to use this
                            website, you agree to their use.&nbsp;&nbsp;
                            <Button
                                className={cookiesButton}
                                key='contact'
                                onClick={handleSubmit}
                            >
                                I Accept
                            </Button>
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Cookies;
