import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography, useMediaQuery } from '@mui/material';
import logoSrc from '../../resources/logo_light_small.png';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    footer: {
        padding: '40px 10px',
        backgroundColor: '#5b8219',
        color: '#FFFFFF',
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        maxWidth: '1280px',
    },
    footerColumnA: {
        width: '280px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    footerColumnB: {
        width: '280px',
        display: 'flex',
        marginTop: '20px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    modo: {
        fontWeight: 300,
        color: '#FDFAFA',
        textAlign: 'center',
    },
    footerHeading: {
        textDecoration: 'underline',
    },
    menuButton: {
        fontWeight: 700,
        size: '18px',
        marginLeft: '2px',
    },
    footerBottom: {
        backgroundColor: '#32470E',
        textAlign: 'center',
        color: '#FFFFFF',
        padding: '20px 10px',
    },
    footerBottomText: {
        margin: '0!important',
    },
    centered: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const Footer = () => {
    const {
        footer,
        footerContainer,
        footerColumnA,
        footerColumnB,
        modo,
        footerHeading,
        menuButton,
        footerBottom,
        footerBottomText,
        centered,
    } = useStyles();
    let currentYear = new Date().getFullYear();

    const postAILogo = (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <img src={logoSrc} height={60} alt='Home' />
        </div>
    );

    const tagline = <Typography paragraph>Generate Posts using AI</Typography>;

    const postAIModo = (
        <Typography variant='h6' component='h6' className={modo}>
            An AIModels Initiative
        </Typography>
    );

    const links = (
        <Typography variant='h5' component='h5' className={footerHeading}>
            Important Links
        </Typography>
    );

    const information = (
        <Typography variant='h5' component='h5' className={footerHeading}>
            Information
        </Typography>
    );

    const isMobile = useMediaQuery('(max-width:520px)');
    return (
        <>
            <footer className={footer}>
                <div className={footerContainer}>
                    <div
                        style={
                            !isMobile
                                ? {
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                      flexWrap: 'wrap',
                                  }
                                : {}
                        }
                    >
                        <div
                            className={
                                isMobile
                                    ? `${footerColumnA} ${centered}`
                                    : footerColumnA
                            }
                        >
                            {postAILogo}
                            {tagline}
                            {postAIModo}
                        </div>

                        <div
                            className={
                                isMobile
                                    ? `${footerColumnB} ${centered}`
                                    : footerColumnB
                            }
                        >
                            {links}
                            <Button
                                className={menuButton}
                                key='contact'
                                color='inherit'
                                href='https://aimodels.ca/contact'
                            >
                                Contact Us
                            </Button>
                            <Button
                                className={menuButton}
                                key='about'
                                color='inherit'
                                href='https://aimodels.ca/#about'
                            >
                                About Us
                            </Button>
                            <Button
                                className={menuButton}
                                key='terms'
                                color='inherit'
                                to='/terms-and-conditions'
                                component={RouterLink}
                            >
                                Terms & Conditions
                            </Button>
                        </div>

                        <div
                            className={
                                isMobile
                                    ? `${footerColumnB} ${centered}`
                                    : footerColumnB
                            }
                        >
                            {information}
                            <Button
                                className={menuButton}
                                key='contactemail'
                                color='inherit'
                                href='mailto:aimodelstech@gmail.com'
                            >
                                aimodelstech@gmail.com
                            </Button>
                            <Button
                                className={menuButton}
                                key='contactaddress'
                                color='inherit'
                            >
                                Greater Vancouver, BC, Canada
                            </Button>
                        </div>
                    </div>
                </div>
            </footer>
            <div className={footerBottom}>
                <Typography paragraph className={footerBottomText}>
                    Copyright &copy; {currentYear} AiModels. All Rights Reserved
                </Typography>
            </div>
        </>
    );
};

export default Footer;
