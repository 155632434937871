import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import PostForm from '../../components/PostForm/PostForm';
import Footer from '../../components/Footer/Footer';
import Cookies from '../../components/Cookies/Cookies';

function Home(props) {
    const [homeAction, setHomeAction] = useState(null);
    const [paramsFunction, setParamsFunction] = useState(null);
    const [cookiesDisplay, setCookiesDisplay] = useState(true);

    return (
        <>
            <Header
                user={props.user}
                updateUser={props.updateUser}
                homeAction={homeAction}
                paramsFunction={paramsFunction}
            />
            <PostForm
                user={props.user}
                updateUser={props.updateUser}
                setHomeAction={setHomeAction}
                setParamsFunction={setParamsFunction}
            />
            <Footer />
            {cookiesDisplay && (
                <Cookies
                    cookiesDisplay={cookiesDisplay}
                    setCookiesDisplay={setCookiesDisplay}
                />
            )}
        </>
    );
}

export default Home;
