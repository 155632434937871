import { initializeApp } from 'firebase/app';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';

const proxyHost = process.env.REACT_APP_PROXY_HOST || 'http://localhost:5000';
const firebaseAuthDomain =
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
    'postai-dev-ae15b.firebaseapp.com';
const firebaseProjectId =
    process.env.REACT_APP_FIREBASE_PROJECT_ID || 'postai-dev-ae15b';
const firebaseStorageBucket =
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
    'postai-dev-ae15b.appspot.com';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: firebaseAuthDomain,
    projectId: firebaseProjectId,
    storageBucket: firebaseStorageBucket,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const logInWithEmailAndPassword = async (email, password, errorHandler) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        errorHandler(err.message.replace('Firebase: ', ''));
    }
};

const signInWithGoogle = async (errorHandler) => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        let response_data = null;
        response_data = await getUser(user.uid);
        if (response_data?.user?.uid !== user.uid) {
            await fetch(`${proxyHost}/users/create`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    uid: user.uid,
                    name: user.displayName,
                    posts: 10,
                    totalPostsCreated: 0,
                    authProvider: 'google',
                    email: user.email,
                    whitelisted: true,
                    isAdmin: false,
                    allowWebSearch: false,
                }),
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Headers': '*',
                },
                credentials: 'include',
            });
        }
    } catch (err) {
        errorHandler(err.message.replace('Firebase: ', ''));
    }
};

const registerWithEmailAndPassword = async (
    name,
    email,
    password,
    errorHandler
) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await fetch(`${proxyHost}/users/create`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                uid: user.uid,
                name: name,
                posts: 10,
                authProvider: 'local',
                email: email,
                whitelisted: true,
                isAdmin: false,
                allowWebSearch: false,
            }),
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        });
    } catch (err) {
        errorHandler(err.message.replace('Firebase: ', ''));
    }
};

const sendPasswordReset = async (email, errorHandler) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert('Password reset link sent!');
    } catch (err) {
        errorHandler(err.message.replace('Firebase: ', ''));
    }
};

const logout = async () => {
    signOut(auth);
    await fetch(`${proxyHost}/logout`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Headers': '*',
        },
        credentials: 'include',
    });
};

const getUsers = async () => {
    try {
        let response_data = [];
        await fetch(`${proxyHost}/users`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.data.users) {
                    response_data = json.data.users;
                }
            });
        return response_data;
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
    return [];
};

const getUser = async (uid) => {
    try {
        let response_data = {};
        await fetch(`${proxyHost}/users/${uid}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => {
                response_data = json.data;
            });
        return response_data;
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
    return {};
};

const updateUserEntry = async (uid, key, value) => {
    try {
        await fetch(`${proxyHost}/users/${uid}/update`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ [key]: value }),
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        });
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
};

const getUserWhitelisted = async (uid) => {
    try {
        let response_data = false;
        await fetch(`${proxyHost}/whitelist/${uid}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.data.whitelisted) {
                    response_data = json.data.whitelisted;
                }
            });
        return response_data;
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
    return false;
};

const getUsersWhitelist = async () => {
    try {
        let response_data = [];
        await fetch(`${proxyHost}/whitelist/users`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.data.users) {
                    response_data = json.data.users;
                }
            });
        return response_data;
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
    return null;
};

const getDomainWhitelist = async () => {
    try {
        let response_data = [];
        await fetch(`${proxyHost}/whitelist/domains`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.data.domains) {
                    response_data = json.data.domains;
                }
            });
        return response_data;
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
    return null;
};

const addUserWhitelist = async (uid) => {
    try {
        await fetch(`${proxyHost}/whitelist/users/add`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ uid: uid }),
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        });
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
};

const removeUserWhitelist = async (uid) => {
    try {
        await fetch(`${proxyHost}/whitelist/users/remove`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ uid: uid }),
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        });
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
};

const addDomainWhitelist = async (domain) => {
    try {
        await fetch(`${proxyHost}/whitelist/domains/add`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ domain: domain }),
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        });
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
};

const removeDomainWhitelist = async (domain) => {
    try {
        await fetch(`${proxyHost}/whitelist/domains/remove`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ domain: domain }),
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        });
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
};

const generateAPIKey = async (uid) => {
    try {
        await fetch(`${proxyHost}/users/${uid}/key/generate`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        });
    } catch (err) {
        console.log(err.message.replace('Firebase: ', ''));
    }
};

export {
    auth,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    getUsers,
    getUser,
    updateUserEntry,
    getDomainWhitelist,
    getUsersWhitelist,
    getUserWhitelisted,
    addUserWhitelist,
    removeUserWhitelist,
    addDomainWhitelist,
    removeDomainWhitelist,
    generateAPIKey,
};
