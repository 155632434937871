import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Badge,
    Tooltip,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
} from '@mui/material';
import { BrowserView } from 'react-device-detect';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { auth } from '../Firebase/Firebase';
import logoSrc from '../../resources/logo_light.png';
import { sendEmailVerification } from 'firebase/auth';
import { useDialog } from '../../components/Dialog/DialogContext';
import { generateAPIKey } from '../../components/Firebase/Firebase';
import { logout } from '../../components/Firebase/Firebase';

const useStyles = makeStyles(() => ({
    header: {
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    logo: {
        position: 'relative',
        top: '-40px',
        height: 75,
    },
    menuButton: {
        fontWeight: 700,
        size: '18px',
        marginLeft: '2px',
    },
    emailVerificationNotification: {
        textAlign: 'center',
        backgroundColor: '#fdb85f',
        padding: '10px',
        marginBottom: '80px',
    },
    resendVerificationLink: {
        color: '#000000',
        textDecoration: 'underline',
        fontWeight: 700,
        size: '18px',
    },
    toastStyle: {
        width: '100%',
        backgroundColor: '#99ff99',
        maxWidth: 'max-content',
        padding: '16px 40px',
        borderRadius: '8px',
        margin: 'auto',
    },
    show: {
        display: 'block',
    },
}));

const Header = (props) => {
    const {
        header,
        toolbar,
        logo,
        menuButton,
        emailVerificationNotification,
        resendVerificationLink,
        toastStyle,
        show,
    } = useStyles();

    const {
        dialogAction,
        dialogTitle,
        dialogText,
        setDialogAction,
        setDialogTitle,
        setDialogText,
        setDialogOpen,
        dialogOpen,
    } = useDialog();

    const handleSignOutDialog = () => {
        setDialogAction('sign-out');
        setDialogTitle('Sign Out');
        setDialogText(
            <DialogContentText>
                Are you sure you want to sign out?
            </DialogContentText>
        );
        setDialogOpen(true);
    };

    const postAILogo = (
        <div className={logo}>
            <img src={logoSrc} height={150} alt='Home' />
        </div>
    );

    let user = props.user;
    let updateUser = props.updateUser;
    let homeAction = props.homeAction;
    let paramsFunction = props.paramsFunction;

    const getLoginLink = () => {
        if (paramsFunction == null) {
            return '/login';
        } else {
            return `/login?${paramsFunction()}`;
        }
    };

    const handleGenerate = async () => {
        await generateAPIKey(user.uid);
        setDialogOpen(false);
        await updateUser();
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const handleSignOut = () => {
        logout();
        window.location.href = '/login';
    };

    const [toast, setToast] = useState({
        message: '',
        visible: false,
    });

    const showToast = (message) => {
        setToast({ message: message, visible: true });

        setTimeout(() => {
            setToast({ message: '', visible: false });
        }, 3000);
    };

    const sendVerificationEmail = async () => {
        try {
            await sendEmailVerification(auth.currentUser);
            showToast('Verification Email Sent!');
        } catch (error) {
            console.error(error);
        }
    };

    function Toast({ message, visible }) {
        return (
            <div
                style={{
                    width: '100%',
                    position: 'fixed',
                    zIndex: 10,
                    top: 50,
                }}
            >
                <div className={visible ? `${show} ${toastStyle}` : ``}>
                    <span>{message}</span>
                </div>
            </div>
        );
    }

    useEffect(() => {
        if (auth.currentUser?.emailVerified === false) {
            sendEmailVerification(auth.currentUser);
            showToast('Verification Email Sent!');
        }
    }, []);

    const isMobile = useMediaQuery('(max-width:520px)');

    return (
        <header>
            <AppBar
                className={header}
                color={'header'}
                position='relative'
                sx={{ marginTop: 0, marginBottom: 15 }}
            >
                <Toolbar className={toolbar}>
                    {homeAction != null ? (
                        <Button
                            className={menuButton}
                            key='home'
                            color='inherit'
                            onClick={homeAction}
                            sx={{ marginLeft: '10px' }}
                        >
                            {postAILogo}
                        </Button>
                    ) : (
                        <Button
                            className={menuButton}
                            key='home'
                            color='inherit'
                            to='/'
                            component={RouterLink}
                            sx={{ marginLeft: '10px' }}
                        >
                            {postAILogo}
                        </Button>
                    )}
                    {user.uid ? (
                        <div style={{ display: 'flex', marginLeft: '28px' }}>
                            <Tooltip title='Post History'>
                                <Button
                                    className={menuButton}
                                    key='postHistory'
                                    color='inherit'
                                    to='/account/history'
                                    component={RouterLink}
                                >
                                    <HistoryIcon style={{ color: '#FDFAFA' }} />
                                </Button>
                            </Tooltip>
                            {!isMobile && (
                                <Tooltip title='Posts'>
                                    <Button
                                        className={menuButton}
                                        key='posts'
                                        color='inherit'
                                        to='/account'
                                        component={RouterLink}
                                    >
                                        <Badge
                                            badgeContent={user.posts}
                                            color='primary'
                                        >
                                            <CreditCardIcon
                                                style={{ color: '#FDFAFA' }}
                                            />
                                        </Badge>
                                    </Button>
                                </Tooltip>
                            )}
                            <Tooltip title='Account'>
                                <Button
                                    className={menuButton}
                                    key='account'
                                    color='inherit'
                                    to='/account'
                                    component={RouterLink}
                                >
                                    <AccountCircleIcon
                                        style={{ color: '#FDFAFA' }}
                                    />
                                </Button>
                            </Tooltip>
                            <Tooltip title='Logout'>
                                <Button
                                    className={menuButton}
                                    key='logout'
                                    style={{ color: '#FDFAFA' }}
                                    onClick={handleSignOutDialog}
                                >
                                    <BrowserView>Sign Out</BrowserView>
                                    <LogoutIcon
                                        sx={{ ml: 1 }}
                                        style={{ color: '#FDFAFA' }}
                                    />
                                </Button>
                            </Tooltip>
                            <Dialog
                                open={dialogOpen}
                                onClose={handleClose}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleGenerate();
                                    }
                                }}
                            >
                                <DialogTitle>{dialogTitle}</DialogTitle>
                                <DialogContent>{dialogText}</DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    {dialogAction === 'sign-out' && (
                                        <Button
                                            onClick={handleSignOut}
                                            autoFocus
                                            to='/login'
                                            component={RouterLink}
                                        >
                                            Sign Out
                                        </Button>
                                    )}
                                </DialogActions>
                            </Dialog>
                        </div>
                    ) : (
                        <div>
                            <Button
                                className={menuButton}
                                key='login'
                                style={{ color: '#FDFAFA' }}
                                to={getLoginLink()}
                                component={RouterLink}
                                sx={{
                                    marginLeft: '20px',
                                    marginRight: '50px',
                                    width: '100%',
                                }}
                            >
                                Sign In
                                <LoginIcon
                                    sx={{ ml: 1 }}
                                    style={{ color: '#FDFAFA' }}
                                />
                            </Button>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
            {auth.currentUser && !user.emailVerified && (
                <div className={emailVerificationNotification}>
                    <p>Please verify your email to use the service!</p>
                    <p>
                        Didn't receive verification email on {user.email}?{' '}
                        <Button
                            className={resendVerificationLink}
                            onClick={sendVerificationEmail}
                        >
                            Resend
                        </Button>
                    </p>
                </div>
            )}
            <Toast message={toast.message} visible={toast.visible} />
        </header>
    );
};

export default Header;
