import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { sendPasswordReset } from '../../components/Firebase/Firebase';
import {
    InputLabel,
    Button,
    Box,
    Typography,
    Container,
    FormControl,
    FilledInput,
    Alert,
    AlertTitle,
} from '@mui/material';

function Reset() {
    const [values, setValues] = useState({});
    const [cancelled, setCancelled] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [errorMsg, setErrorMsg] = useState(<></>);
    const navigate = useNavigate();

    const handleChange = (e) => {
        let { value, name } = e.target;

        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleError = (errorMessage) => {
        setErrorMsg(
            <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
            </Alert>
        );
        setErrorState(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        sendPasswordReset(values.email, handleError);
    };

    const handleCancel = () => {
        setCancelled(true);
    };

    useEffect(() => {
        if (cancelled) navigate('/');
    }, [cancelled, navigate]);

    return (
        <Container component='main' maxWidth='xs'>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='100vh'
                flexDirection='column'
            >
                <Typography component='h1' variant='h5' sx={{ mb: 2 }}>
                    Reset Password
                </Typography>
                {errorState && errorMsg}
                <Box component='form' onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <FormControl fullWidth sx={{ my: 1 }} variant='outlined'>
                        <InputLabel>Email</InputLabel>
                        <FilledInput
                            type='text'
                            label='Email'
                            name='email'
                            required={true}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <Button
                        fullWidth
                        sx={{ my: 1, height: 45 }}
                        variant='contained'
                        color='primary'
                        type='submit'
                    >
                        Send Password Reset Email
                    </Button>
                    <Button
                        fullWidth
                        sx={{ my: 1, height: 45 }}
                        variant='contained'
                        color='secondary'
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Box display='flex' justifyContent='center'>
                        <Typography component='p' variant='body1'>
                            Don't have an account?{' '}
                            <Link to='/register'>Sign Up</Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default Reset;
