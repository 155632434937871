import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { fetchPostHistory } from '../ServerRequests/ServerRequests';
import {
    Container,
    Typography,
    Card,
    CardContent,
    IconButton,
    Tooltip,
    CardHeader,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Popover,
    Box,
    Slider,
    Input,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString(
        undefined,
        options
    );
    const timeString = new Date(dateString).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    });
    return `${formattedDate} - ${timeString}`;
}

function PostHistory(props) {
    let user = props.user;

    useEffect(() => {
        fetchPostHistory(user.uid)
            .then((data) => {
                const sortedHistory = data.sort(
                    (a, b) => new Date(b.date) - new Date(a.date)
                );
                setPostHistory(sortedHistory);
            })
            .catch((error) =>
                console.error('Error fetching post history:', error)
            );
    }, [user.uid]);

    const [postHistory, setPostHistory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedPostStyle, setSelectedPostStyle] = useState('All');
    const [selectedTone, setSelectedTone] = useState('All');
    const [selectedDate, setSelectedDate] = useState('All');
    const [selectedPostLength, setSelectedPostLength] = useState([0, 1000]);
    const [postLengthPopover, setPostLengthPopover] = useState(null);
    const [selectedKeyword, setSelectedKeyword] = useState('');

    function filterByDate(post) {
        if (selectedDate === 'All') {
            return true;
        }

        const postDate = new Date(post.date);

        if (selectedDate === 'Last7Days') {
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
            return postDate >= sevenDaysAgo;
        }

        if (selectedDate === 'Last30Days') {
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
            return postDate >= thirtyDaysAgo;
        }

        return false;
    }

    function filterByKeyword(post) {
        if (!selectedKeyword) {
            return true;
        }

        const keyword = selectedKeyword.toLowerCase();
        const prompt = (String(post.prompt) || '').toLowerCase();
        const generatedPost = (String(post.generatedPost) || '').toLowerCase();

        return prompt.includes(keyword) || generatedPost.includes(keyword);
    }

    const filteredPostHistory = postHistory.filter(
        (post) =>
            (selectedCategory === 'All' ||
                post.category === selectedCategory) &&
            (selectedPostStyle === 'All' || post.style === selectedPostStyle) &&
            (selectedTone === 'All' || post.tone === selectedTone) &&
            isWithinPostLengthRange(post.postLength, selectedPostLength) &&
            filterByDate(post) &&
            filterByKeyword(post)
    );

    function isWithinPostLengthRange(postLength, selectedRange) {
        return postLength >= selectedRange[0] && postLength <= selectedRange[1];
    }

    const handlePostLengthClick = (event) => {
        setPostLengthPopover(event.currentTarget);
    };

    const handlePostLengthClose = () => {
        setPostLengthPopover(null);
    };

    return (
        <>
            <Header user={user} />
            <Container component='main' sx={{ mb: 10 }}>
                <Typography variant='h4' sx={{ marginBottom: '28px' }}>
                    Post History
                </Typography>

                {/* Filter buttons */}
                <Box sx={{ marginBottom: '16px' }}>
                    <FormControl
                        sx={{
                            marginRight: '16px',
                            minWidth: 160,
                            marginBottom: '30px',
                        }}
                    >
                        <InputLabel
                            sx={{ marginTop: '-12px', marginLeft: '-12px' }}
                        >
                            Date
                        </InputLabel>
                        <Select
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                        >
                            <MenuItem value='All'>All</MenuItem>
                            <MenuItem value='Last7Days'>Last 7 Days</MenuItem>
                            <MenuItem value='Last30Days'>Last 30 Days</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ marginRight: '16px', minWidth: 100 }}>
                        <InputLabel
                            sx={{ marginTop: '-12px', marginLeft: '-12px' }}
                        >
                            Style
                        </InputLabel>
                        <Select
                            value={selectedPostStyle}
                            onChange={(e) =>
                                setSelectedPostStyle(e.target.value)
                            }
                        >
                            <MenuItem value='All'>All</MenuItem>
                            <MenuItem value='blog'>Blog</MenuItem>
                            <MenuItem value='tweet'>Tweet</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{
                            marginRight: '16px',
                            minWidth: 100,
                            marginBottom: '30px',
                        }}
                    >
                        <InputLabel
                            sx={{ marginTop: '-12px', marginLeft: '-12px' }}
                        >
                            Category
                        </InputLabel>
                        <Select
                            value={selectedCategory}
                            onChange={(e) =>
                                setSelectedCategory(e.target.value)
                            }
                        >
                            <MenuItem value='All'>All</MenuItem>
                            <MenuItem value='For Fun'>For Fun</MenuItem>
                            <MenuItem value='Marketing'>Marketing</MenuItem>
                            <MenuItem value='Technology'>Technology</MenuItem>
                            <MenuItem value='Business'>Business</MenuItem>
                            <MenuItem value='Food'>Food</MenuItem>
                            <MenuItem value='Entertainment'>
                                Entertainment
                            </MenuItem>
                            <MenuItem value='Sports'>Sports</MenuItem>
                            <MenuItem value='Health & Wellness'>
                                Health & Wellness
                            </MenuItem>
                            <MenuItem value='Travel'>Travel</MenuItem>
                            <MenuItem value='Personal Development'>
                                Personal Development
                            </MenuItem>
                            <MenuItem value='Other'>Other</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{
                            marginRight: '16px',
                            minWidth: 100,
                            marginBottom: '30px',
                        }}
                    >
                        <InputLabel
                            sx={{ marginTop: '-12px', marginLeft: '-12px' }}
                        >
                            Tone
                        </InputLabel>
                        <Select
                            value={selectedTone}
                            onChange={(e) => setSelectedTone(e.target.value)}
                        >
                            <MenuItem value='All'>All</MenuItem>
                            <MenuItem value='positive'>Positive 😃</MenuItem>
                            <MenuItem value='negative'>Negative 😐</MenuItem>
                            <MenuItem value='neutral'>Neutral 😠</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ marginRight: '16px', minWidth: 80 }}>
                        <InputLabel
                            sx={{
                                marginTop: '-36px',
                                marginBottom: '20px',
                                marginLeft: '-12px',
                                fontSize: '12px',
                                minWidth: 120,
                            }}
                        >
                            Post Length
                        </InputLabel>
                        <Button
                            sx={{ marginTop: '6px' }}
                            onClick={handlePostLengthClick}
                        >
                            {selectedPostLength[0]} - {selectedPostLength[1]}
                        </Button>
                    </FormControl>

                    <Popover
                        open={Boolean(postLengthPopover)}
                        anchorEl={postLengthPopover}
                        onClose={handlePostLengthClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box px={3} pt={2} minWidth='200px'>
                            <Slider
                                value={selectedPostLength}
                                onChange={(event, newValue) =>
                                    setSelectedPostLength(newValue)
                                }
                                valueLabelDisplay='auto'
                                valueLabelFormat={(value) => value.toString()}
                                min={0}
                                max={1000}
                                step={10}
                                marks={[
                                    { value: 0, label: '0' },
                                    { value: 250, label: '250' },
                                    { value: 500, label: '500' },
                                    { value: 750, label: '750' },
                                    { value: 1000, label: '1000' },
                                ]}
                            />
                        </Box>
                    </Popover>
                    <FormControl
                        sx={{
                            marginRight: '16px',
                            minWidth: 160,
                            marginBottom: '30px',
                        }}
                    >
                        <InputLabel
                            sx={{
                                marginLeft: '-12px',
                                fontSize: '16px',
                                minWidth: 80,
                            }}
                        >
                            Search By Keyword
                        </InputLabel>
                        <Input
                            sx={{ minWidth: 20 }}
                            value={selectedKeyword}
                            onChange={(e) => setSelectedKeyword(e.target.value)}
                        />
                    </FormControl>
                </Box>

                {filteredPostHistory.length === 0 ? (
                    <Typography variant='body1' sx={{ marginBottom: '16px' }}>
                        Sorry, there are no posts which match the selected
                        filters!
                    </Typography>
                ) : (
                    filteredPostHistory.map((post, index) => (
                        <Card key={index} sx={{ mb: 2 }}>
                            <CardHeader
                                title={`Post #${
                                    filteredPostHistory.length - index
                                }`}
                                action={
                                    <Tooltip title='Copy to Clipboard'>
                                        <IconButton
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    post.generatedPost
                                                );
                                            }}
                                        >
                                            <FileCopyIcon color='primary' />
                                        </IconButton>
                                    </Tooltip>
                                }
                                sx={{
                                    marginBottom: '-16px',
                                }}
                            />
                            <CardContent>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    Date: {formatDate(post.date)}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    Style: {post.style}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    Category: {post.category}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    Tone: {post.tone}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    Post Length: {post.postLength}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                >
                                    Prompt: {post.prompt}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                    style={{
                                        whiteSpace: 'pre-wrap',
                                        marginTop: '8px',
                                    }}
                                >
                                    Generated Post:
                                    <br />
                                    {String(post.generatedPost)}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))
                )}
            </Container>
        </>
    );
}

export default PostHistory;
