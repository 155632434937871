import React from 'react';
import { Tooltip } from '@mui/material';

const postLengths = {
    tweet: {
        short: 150,
        medium: 200,
        long: 250,
    },
    blog: {
        short: 280,
        medium: 650,
        long: 1000,
    },
};

const CircleButton = ({ selected, onClick, label, tooltip }) => {
    return (
        <Tooltip title={tooltip}>
            <div
                style={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    padding: '8px',
                }}
            >
                <div
                    onClick={onClick}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            backgroundColor: selected ? '#619b8a' : 'white',
                            border: '2px solid black',
                            marginBottom: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'background-color 0.3s ease-in-out',
                        }}
                    ></div>
                    <span style={{ fontSize: '14px' }}>{label}</span>
                </div>
            </div>
        </Tooltip>
    );
};

const PostLengthSelector = ({ style, currentLength, handleChange }) => {
    const handleLengthButtonClick = (length) => {
        handleChange({ target: { name: 'postLength', value: length } });
        console.log(length);
    };

    const shortLength = postLengths[style].short;
    const mediumLength = postLengths[style].medium;
    const longLength = postLengths[style].long;

    if (currentLength === null) {
        currentLength = mediumLength;
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
                maxWidth: '320px',
                margin: '0 auto',
            }}
        >
            <CircleButton
                selected={currentLength === shortLength}
                onClick={() => handleLengthButtonClick(shortLength)}
                label='Short'
                tooltip={`~${shortLength} chars`}
            />
            <CircleButton
                selected={currentLength === mediumLength}
                onClick={() => handleLengthButtonClick(mediumLength)}
                label='Medium'
                tooltip={`~${mediumLength} chars`}
            />
            <CircleButton
                selected={currentLength === longLength}
                onClick={() => handleLengthButtonClick(longLength)}
                label='Long'
                tooltip={`~${longLength} chars`}
            />
        </div>
    );
};

export default PostLengthSelector;

export { postLengths };
