import { useEffect, useState, useCallback } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    auth,
    getUserWhitelisted,
    updateUserEntry,
    getUser,
} from '../Firebase/Firebase';
import { authenticate } from '../ServerRequests/ServerRequests';

const defaultPosts = 10;

const setValue = async (user, updateUser, key, value) => {
    await updateUserEntry(user.uid, key, value);
    await updateUser();
};

const usedPost = async (user, updateUser, numberOfPosts = 1) => {
    await setValue(user, updateUser, 'posts', user.posts - numberOfPosts);
    await setValue(
        user,
        updateUser,
        'totalPostsCreated',
        user.totalPostsCreated + 1
    );
};

const updateName = async (user, updateUser, newName) => {
    setValue(user, updateUser, 'name', newName);
};

function User() {
    const [authUser, loading, error] = useAuthState(auth);
    const [user, setUser] = useState({});
    const [whitelisted, setWhitelisted] = useState(false);
    const [updatedUser, setUpdatedUser] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState(false);

    const updateUser = useCallback(async () => {
        const userData = await getUser(user.uid);
        setUser({
            ...user,
            ...userData.user,
        });
        setUpdatedUser(true);
    }, [user]);

    const checkUserWhitelisted = useCallback(async () => {
        setWhitelisted(await getUserWhitelisted(user.uid));
    }, [user]);

    const checkRefreshPosts = useCallback(() => {
        const refreshPosts = async () => {
            if (user.posts == null || user.posts < defaultPosts) {
                setValue(user, updateUser, 'posts', defaultPosts);
            }
            const now = new Date();
            const next = new Date(now);
            next.setDate(next.getDate() + 1);
            next.setHours(0, 0, 0, 0);
            setValue(user, updateUser, 'lastPostRefresh', now.toString());
            setValue(user, updateUser, 'nextPostRefresh', next.toString());
        };
        if (user.nextPostRefresh) {
            const dateToCheck = new Date(Date.parse(user.nextPostRefresh));
            if (dateToCheck <= new Date()) {
                refreshPosts();
            }
        } else if (user.posts !== null) {
            refreshPosts();
        }
    }, [user, updateUser]);

    useEffect(() => {
        if (loading) return;
        if (error) {
            console.log(error);
            return;
        }
        if (!authUser) return;
        setUser({ ...authUser, auth: authUser });
    }, [loading, error, authUser]);

    useEffect(() => {
        if (!user) return;
        if (!user.uid) return;
        if (!updatedUser) return;
        checkUserWhitelisted();
    }, [user, updatedUser, checkUserWhitelisted]);

    useEffect(() => {
        if (user.uid && whitelisted) {
            checkRefreshPosts();
        }
    }, [whitelisted, user, checkRefreshPosts]);

    if (user.uid && !updatedUser) {
        updateUser();
    }

    if (user.apiKey && !authenticatedUser) {
        authenticate(user.apiKey);
        setAuthenticatedUser(true);
    }

    return [user, updateUser];
}

export { User, usedPost, updateName, setValue };
