const proxyHost = process.env.REACT_APP_PROXY_HOST || 'http://localhost:5000';

const authenticate = async (apiKey) => {
    try {
        await fetch(`${proxyHost}/authenticate`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ apiKey: apiKey }),
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        });
        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
};

const generatePost = async (
    style,
    tone,
    category,
    postLength,
    prompt,
    noWebSearch,
    errorHandler,
    userUid
) => {
    let response_data = null;
    try {
        await fetch(`${proxyHost}/post/generate`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                style: style,
                tone: tone,
                category: category,
                postLength: postLength,
                prompt: prompt,
                noWebSearch: noWebSearch,
            }),
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else {
                    return response.json();
                }
            })
            .then((json) => {
                response_data = json;
            });

        if (response_data.success) {
            await fetch(`${proxyHost}/users/${userUid}/post_history`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    category: category,
                    date: new Date().toISOString(),
                    postLength: postLength,
                    style: style,
                    tone: tone,
                    prompt: prompt,
                    generatedPost: response_data.data[0].message.content, // this is how to access the generated post string
                }),
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Headers': '*',
                },
                credentials: 'include',
            });
        }

        return {
            success: response_data.success,
            choices: response_data.data,
        };
    } catch (err) {
        errorHandler(err.toString());
        return {
            success: false,
            response: err.toString(),
        };
    }
};

const fetchPostHistory = async (userUid) => {
    const userPostHistoryResponse = await fetch(
        `${proxyHost}/users/${userUid}/post_history`,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        }
    );

    const userPostHistoryData = await userPostHistoryResponse.json();
    return userPostHistoryData.data.user.post_history;
};

const generateImage = async (prompt, samples, errorHandler) => {
    let response_data = null;
    try {
        await fetch(`${proxyHost}/image/generate`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                samples: samples,
                prompt: prompt,
            }),
            headers: {
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': 'https://postai.aimodels.ca',
                'Access-Control-Allow-Credentials': true,
                'Access-Control-Allow-Headers': '*',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => {
                response_data = json;
            });
        return {
            success: response_data.success,
            choices: response_data.data,
        };
    } catch (err) {
        console.log(err);
        errorHandler(err.toString());
        return {
            success: false,
            response: err.toString(),
        };
    }
};

export { authenticate, generatePost, generateImage, fetchPostHistory };
