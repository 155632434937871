import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    InputLabel,
    Box,
    Typography,
    Container,
    Card,
    CardContent,
    Button,
    Tooltip,
    Grid,
    TextField,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ReplayIcon from '@mui/icons-material/Replay';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { updateName } from '../../components/User/User';
import {
    getUserWhitelisted,
    generateAPIKey,
} from '../../components/Firebase/Firebase';

function Account(props) {
    let user = props.user;
    let updateUser = props.updateUser;
    const [editMode, setEditMode] = useState(false);
    const [values, setValues] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogText, setDialogText] = useState('');
    const [apiKeyVisible, setApiKeyVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const timeToPostRefresh = () => {
        if (!user?.nextPostRefresh) {
            return null;
        }
        const dateToCheck = new Date(Date.parse(user.nextPostRefresh));
        const diff = dateToCheck - new Date();
        const hours = (diff / 3.6e6) | 0;
        const mins = ((diff % 3.6e6) / 6e4) | 0;
        return { hours, mins };
    };

    const handleChange = (e) => {
        let { value, name } = e.target;

        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleNameChange = () => {
        setEditMode(false);
        if (values.name) {
            updateName(user, updateUser, values.name);
        }
    };

    const handleRegenerate = () => {
        setDialogTitle('Regenerate API Key');
        setDialogText(
            <DialogContentText>
                Are you sure you want to regenerate this api key?
                <br />
                <strong>It will be lost forever!</strong>
            </DialogContentText>
        );
        setDialogOpen(true);
    };

    const handleGenerate = async () => {
        await generateAPIKey(user.uid);
        setDialogOpen(false);
        await updateUser();
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (!user) return;
        if (!user?.uid) {
            return navigate(`/login?redirectTo=${location.pathname}`);
        }
    }, [user, location, navigate]);

    const diff_date = timeToPostRefresh();

    return (
        <>
            <Header user={user} />
            <Container component='main'>
                <Box
                    display='flex'
                    alignItems='center'
                    minHeight='100vh'
                    flexDirection='column'
                >
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                handleGenerate();
                            }
                        }}
                    >
                        <DialogTitle>{dialogTitle}</DialogTitle>
                        <DialogContent>{dialogText}</DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleGenerate} autoFocus>
                                Regenerate
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Grid container spacing={3}>
                        <Grid item xs={isMobile ? 8 : 10}>
                            <Box sx={{ flex: '1 1 auto' }} />
                        </Grid>
                        <Grid item xs={1}>
                            {user.isAdmin && (
                                <Tooltip title='users'>
                                    <Button
                                        to='/users'
                                        component={RouterLink}
                                        sx={{ ml: 1 }}
                                    >
                                        <PeopleIcon />
                                    </Button>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item xs={1}>
                            {!editMode ? (
                                <Tooltip title='edit'>
                                    <Button
                                        onClick={() => {
                                            setEditMode(true);
                                        }}
                                        sx={{ ml: 2, mr: 2 }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Tooltip title='done'>
                                    <Button
                                        onClick={handleNameChange}
                                        sx={{ ml: 2, mr: 2 }}
                                    >
                                        <DoneIcon />
                                    </Button>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                    <Card sx={{ width: 1 }}>
                        <CardContent>
                            <Typography component='h1' variant='h5'>
                                Account Info
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <InputLabel>Name</InputLabel>
                            {editMode ? (
                                <TextField
                                    sx={{ height: '100%' }}
                                    size='small'
                                    id='content-output'
                                    name='name'
                                    variant='filled'
                                    color='primary'
                                    value={
                                        values.name ? values.name : user.name
                                    }
                                    fullWidth={true}
                                    onChange={handleChange}
                                />
                            ) : (
                                <Typography component='p' variant='body1'>
                                    {user?.name
                                        ? user.name
                                        : 'Click edit to add your name.'}
                                </Typography>
                            )}
                        </CardContent>
                        <CardContent>
                            <InputLabel>Email</InputLabel>
                            <Typography component='p' variant='body1'>
                                {user.email}
                            </Typography>
                        </CardContent>
                        {user.isAdmin && (
                            <CardContent>
                                <InputLabel>Admin</InputLabel>
                                <Typography component='p' variant='body1'>
                                    User is admin
                                </Typography>
                            </CardContent>
                        )}
                        {!getUserWhitelisted(user.uid) && (
                            <CardContent>
                                <InputLabel>Whitelisted</InputLabel>
                                <Typography component='p' variant='body1'>
                                    User is <strong>NOT</strong> whitelisted
                                    <br />
                                    If you would like to be whitelisted please
                                    email <i>aimodelstech@gmail.com</i> with:
                                    <br />
                                    <li>
                                        subject: PostAI request for whitelist
                                    </li>
                                    <li>Email address of account</li>
                                    <li>Reason for request</li>
                                    <li>Planned usage</li>
                                </Typography>
                            </CardContent>
                        )}
                        <CardContent>
                            <InputLabel>Posts</InputLabel>
                            <Typography component='p' variant='body1'>
                                {user?.posts ? user.posts : 0}
                            </Typography>
                        </CardContent>
                        {getUserWhitelisted(user.uid) && (
                            <CardContent>
                                <InputLabel>Next Posts Refresh</InputLabel>
                                <Typography component='p' variant='body1'>
                                    {diff_date
                                        ? `${diff_date.hours}H:${diff_date.mins}M`
                                        : 'N/A'}
                                </Typography>
                            </CardContent>
                        )}
                        <CardContent>
                            <InputLabel>API Key</InputLabel>
                            <Box
                                display='flex'
                                alignItems='space-betwee'
                                minWidth='100vh'
                                flexDirection='row'
                            >
                                <Typography
                                    component='p'
                                    variant='body1'
                                    sx={{ width: '95%' }}
                                >
                                    {apiKeyVisible ? (
                                        <>
                                            {user?.apiKey ? user.apiKey : 'N/A'}
                                        </>
                                    ) : (
                                        '****************'
                                    )}
                                </Typography>
                                {apiKeyVisible ? (
                                    <Tooltip title='hide API key'>
                                        <Button
                                            onClick={() => {
                                                setApiKeyVisible(false);
                                            }}
                                        >
                                            <VisibilityOffIcon />
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title='show API key'>
                                        <Button
                                            onClick={() => {
                                                setApiKeyVisible(true);
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                                {editMode && (
                                    <Tooltip title='regenerate API key'>
                                        <Button onClick={handleRegenerate}>
                                            <ReplayIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default Account;
