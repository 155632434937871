import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
import {
    auth,
    registerWithEmailAndPassword,
    signInWithGoogle,
} from '../../components/Firebase/Firebase';
import {
    InputLabel,
    Button,
    Box,
    Typography,
    Container,
    InputAdornment,
    FormControl,
    FilledInput,
    IconButton,
    Avatar,
    Grid,
    Alert,
    AlertTitle,
} from '@mui/material';
import googlePNG from '../../resources/google.png';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Register() {
    const [values, setValues] = useState({});
    const [cancelled, setCancelled] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const [showPassword, setShowPassword] = React.useState(false);
    const [errorState, setErrorState] = useState(false);
    const [errorMsg, setErrorMsg] = useState(<></>);
    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleChange = (e) => {
        let { value, name } = e.target;

        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleError = (errorMessage) => {
        setErrorMsg(
            <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
            </Alert>
        );
        setErrorState(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        registerWithEmailAndPassword(
            values.name,
            values.email,
            values.password,
            handleError
        );
    };

    const handleCancel = () => {
        setCancelled(true);
    };

    const handleContinueWithGoogle = () => {
        // TODO: set loading
        signInWithGoogle(handleError);
    };

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (error) {
            console.log(error);
            return;
        }
        if (user || cancelled) navigate('/');
    }, [user, loading, error, cancelled, navigate]);

    return (
        <Container component='main' maxWidth='xs'>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='100vh'
                flexDirection='column'
            >
                <Typography component='h1' variant='h5' sx={{ mb: 2 }}>
                    Create an Account
                </Typography>
                {errorState && errorMsg}
                <Box component='form' onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <FormControl fullWidth sx={{ my: 1 }} variant='outlined'>
                        <InputLabel>Name</InputLabel>
                        <FilledInput
                            type='text'
                            label='Name'
                            name='name'
                            required={true}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 1 }} variant='outlined'>
                        <InputLabel>Email</InputLabel>
                        <FilledInput
                            type='text'
                            label='Email'
                            name='email'
                            required={true}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 1 }} variant='outlined'>
                        <InputLabel>Password</InputLabel>
                        <FilledInput
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge='end'
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label='Password'
                            name='password'
                            required={true}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <Button
                        fullWidth
                        sx={{ my: 1, height: 45 }}
                        variant='contained'
                        color='primary'
                        type='submit'
                    >
                        Create Account
                    </Button>
                    <Button
                        fullWidth
                        sx={{ my: 1, height: 45 }}
                        variant='contained'
                        color='google'
                        onClick={handleContinueWithGoogle}
                    >
                        <Grid container alignItems='center'>
                            <Grid item xs={2}>
                                <Avatar src={googlePNG} />
                            </Grid>
                            <Grid item xs={8}>
                                Continue with Google
                            </Grid>
                            <Grid item xs={2} />
                        </Grid>
                    </Button>
                    <Button
                        fullWidth
                        sx={{ my: 1, height: 45 }}
                        variant='contained'
                        color='secondary'
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Box display='flex' justifyContent='center'>
                        <Typography component='p' variant='body1'>
                            Already have an account?{' '}
                            <Link to='/login'>Sign In</Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}
export default Register;
