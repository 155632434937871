import React, { useState, useEffect } from 'react';
import { Tooltip, useMediaQuery } from '@mui/material';

const EmojiButton = ({ tone, selected, onClick }) => {
    const isMobile = useMediaQuery('(max-width:520px)');
    const fontSize = isMobile ? '50px' : '75px';

    return (
        <Tooltip title={tone}>
            <button
                type='button'
                onClick={() => onClick(tone)}
                style={{
                    fontSize,
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                    border: 'none',
                }}
                disabled={selected}
            >
                <span
                    style={{
                        color: selected ? 'black' : '#ccc',
                        filter: selected ? 'none' : 'grayscale(100%)',
                    }}
                >
                    {tone === 'positive'
                        ? '😃'
                        : tone === 'neutral'
                        ? '😐'
                        : '😠'}
                </span>
            </button>
        </Tooltip>
    );
};

const EmojiSelector = ({ handleChange }) => {
    const [selectedTone, setSelectedTone] = useState('positive');

    useEffect(() => {}, [selectedTone]);

    const handleSelectTone = (tone) => {
        handleChange({ target: { name: 'tone', value: tone } });
        setSelectedTone(tone);
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
            }}
        >
            <EmojiButton
                tone='positive'
                selected={selectedTone === 'positive'}
                onClick={handleSelectTone}
            />
            <EmojiButton
                tone='neutral'
                selected={selectedTone === 'neutral'}
                onClick={handleSelectTone}
            />
            <EmojiButton
                tone='negative'
                selected={selectedTone === 'negative'}
                onClick={handleSelectTone}
            />
        </div>
    );
};

export default EmojiSelector;
