import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
    auth,
    logInWithEmailAndPassword,
    signInWithGoogle,
} from '../../components/Firebase/Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    InputLabel,
    Button,
    Box,
    Typography,
    Container,
    InputAdornment,
    FormControl,
    FilledInput,
    IconButton,
    Avatar,
    Grid,
    Alert,
    AlertTitle,
} from '@mui/material';
import googlePNG from '../../resources/google.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { isMobile } from 'react-device-detect';

const Login = () => {
    const [values, setValues] = useState({});
    const [emailSignIn, setEmailSignIn] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const [showPassword, setShowPassword] = React.useState(false);
    const [errorState, setErrorState] = useState(false);
    const [errorMsg, setErrorMsg] = useState(<></>);
    const searchParams = useSearchParams()[0];
    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleChange = (e) => {
        let { value, name } = e.target;

        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleError = (errorMessage) => {
        setErrorMsg(
            <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
            </Alert>
        );
        setErrorState(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        logInWithEmailAndPassword(values.email, values.password, handleError);
    };

    const handleCancel = () => {
        if (emailSignIn) {
            setEmailSignIn(false);
        } else {
            setCancelled(true);
        }
    };

    const handleContinue = () => {
        if (!values.email || values.email === '') {
            handleError('Please enter your email.');
        } else {
            setEmailSignIn(true);
        }
    };

    const handleContinueWithGoogle = () => {
        // TODO: set loading
        signInWithGoogle(handleError);
    };

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (error) {
            console.log(error);
            return;
        }
        const redirectTo = searchParams.get('redirectTo');
        searchParams.delete('redirectTo');
        if (cancelled) {
            navigate(`/?${searchParams.toString()}`);
        }
        if (user) {
            navigate(
                redirectTo == null
                    ? `/?${searchParams.toString()}`
                    : `${redirectTo}?${searchParams.toString()}`
            );
        }
    }, [user, loading, error, cancelled, navigate, searchParams]);

    return (
        <Container
            component='main'
            maxWidth='xs'
            sx={{ marginTop: isMobile ? -5 : 0 }}
        >
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                minHeight='100vh'
                flexDirection='column'
            >
                <Typography component='h1' variant='h5' sx={{ mb: 2 }}>
                    Sign In
                </Typography>
                {errorState && errorMsg}
                <Box component='form' onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <FormControl fullWidth sx={{ my: 1 }} variant='outlined'>
                        <InputLabel>Email</InputLabel>
                        <FilledInput
                            type='text'
                            startAdornment={
                                <InputAdornment position='start'>
                                    <AccountCircle />
                                </InputAdornment>
                            }
                            label='Email'
                            name='email'
                            required={true}
                            disabled={emailSignIn}
                            onChange={handleChange}
                        />
                    </FormControl>
                    {emailSignIn ? (
                        <>
                            <FormControl
                                fullWidth
                                sx={{ my: 1 }}
                                variant='outlined'
                            >
                                <InputLabel>Password</InputLabel>
                                <FilledInput
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge='end'
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label='Password'
                                    name='password'
                                    required={true}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <Button
                                fullWidth
                                sx={{ my: 1, height: 45 }}
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                Sign In
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                fullWidth
                                sx={{ my: 1, height: 45 }}
                                variant='contained'
                                color='primary'
                                onClick={handleContinue}
                            >
                                Continue
                            </Button>
                            <Button
                                fullWidth
                                sx={{ my: 1, height: 45 }}
                                variant='contained'
                                color='google'
                                onClick={handleContinueWithGoogle}
                            >
                                <Grid container alignItems='center'>
                                    <Grid item xs={2}>
                                        <Avatar src={googlePNG} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        Continue with Google
                                    </Grid>
                                    <Grid item xs={2} />
                                </Grid>
                            </Button>
                        </>
                    )}
                    <Button
                        fullWidth
                        sx={{ my: 1, height: 45 }}
                        variant='contained'
                        color='secondary'
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Box display='flex' justifyContent='center'>
                        <Typography component='p' variant='body1'>
                            Don't have an account?{' '}
                            <Link to='/register'>Sign Up</Link>
                        </Typography>
                    </Box>
                    <Box display='flex' justifyContent='center'>
                        <Typography component='p' variant='body1'>
                            <Link to='/reset-password'>
                                Forgot your password?
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;
