import React, { createContext, useContext, useState } from 'react';

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
    const [dialogAction, setDialogAction] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogText, setDialogText] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <DialogContext.Provider
            value={{
                dialogAction,
                setDialogAction,
                dialogTitle,
                setDialogTitle,
                dialogText,
                setDialogText,
                dialogOpen,
                setDialogOpen,
            }}
        >
            {children}
        </DialogContext.Provider>
    );
};

export const useDialog = () => useContext(DialogContext);
