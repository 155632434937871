import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box,
    Typography,
    Container,
    Card,
    CardContent,
    Button,
    Tooltip,
    Grid,
    TextField,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Chip,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { setValue } from '../../components/User/User';
import {
    getDomainWhitelist,
    getUsers,
    getUsersWhitelist,
    addUserWhitelist,
    removeUserWhitelist,
    addDomainWhitelist,
    removeDomainWhitelist,
} from '../../components/Firebase/Firebase';

function Users(props) {
    let user = props.user;
    let updateUser = props.updateUser;
    const [updatedUser, setUpdatedUser] = useState(false);
    const [updatedEdited, setUpdatedEdited] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [users, setUsers] = useState(null);
    const [userWhitelist, setUserWhitelist] = useState(null);
    const [domains, setDomains] = useState(null);
    const [domainToAdd, setDomainToAdd] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [editedUsers, setEditedUsers] = useState(null);
    const [editedWhitelist, setEditedWhitelist] = useState(null);
    const [editedDomainList, setEditedDomainList] = useState(null);
    const [userChanges, setUserChanges] = useState({});
    const [domainChanges, setDomainChanges] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    const getEditedUsers = useCallback(() => {
        let updatedUsersList = structuredClone(users);
        Object.entries(userChanges).forEach(([uid, changes]) =>
            Object.entries(changes).forEach(([key, value]) => {
                if (key !== 'whitelisted') {
                    let updateIndex = users.findIndex(
                        (user) => user.uid === uid
                    );
                    updatedUsersList[updateIndex][key] = value;
                }
            })
        );
        setEditedUsers(updatedUsersList);
    }, [users, userChanges]);

    const getEditedWhitelist = useCallback(() => {
        let updatedWhitelist = [...userWhitelist];
        Object.entries(userChanges).forEach(([uid, changes]) =>
            Object.entries(changes).forEach(([key, value]) => {
                if (key === 'whitelisted') {
                    if (value === true) {
                        updatedWhitelist.push(uid);
                    } else {
                        updatedWhitelist = updatedWhitelist.filter(
                            (userUid) => userUid !== uid
                        );
                    }
                }
            })
        );
        setEditedWhitelist(updatedWhitelist);
    }, [userWhitelist, userChanges]);

    const getEditedDomainList = useCallback(() => {
        let updatedDomainList = [...domains];
        Object.entries(domainChanges).forEach(([domain, action]) => {
            if (action === 'add') {
                updatedDomainList.push(domain);
            } else {
                updatedDomainList = updatedDomainList.filter(
                    (domainName) => domainName !== domain
                );
            }
        });
        setEditedDomainList(updatedDomainList);
    }, [domains, domainChanges]);

    useEffect(() => {
        if (users === null || userWhitelist === null || domains === null) {
            const updateData = async () => {
                setUsers(await getUsers());
                setUserWhitelist(await getUsersWhitelist());
                setDomains(await getDomainWhitelist());
            };
            if (!updatedUser) {
                updateData();
                setUpdatedUser(true);
            }
        } else if (
            !updatedEdited &&
            (editedUsers === null ||
                editedWhitelist === null ||
                editedDomainList === null)
        ) {
            getEditedUsers();
            getEditedWhitelist();
            getEditedDomainList();
            setUpdatedEdited(true);
        }
    }, [
        users,
        domains,
        userWhitelist,
        updatedUser,
        editedUsers,
        editedWhitelist,
        editedDomainList,
        updatedEdited,
        getEditedUsers,
        getEditedWhitelist,
        getEditedDomainList,
    ]);

    useEffect(() => {
        if (!user) return;
        if (!user?.uid) {
            return navigate(`/login?redirectTo=${location.pathname}`);
        }
        if (typeof user.isAdmin == 'undefined') return;
        if (user.isAdmin !== true) return navigate('/account');
    }, [user, location, navigate]);

    const handleDomainToAdd = (e) => {
        let { value } = e.target;
        setDomainToAdd(value);
    };

    const timeSinceLastLogin = (date) => {
        if (!date) {
            return 'Never logged in';
        }

        const currentDate = new Date();
        const lastLoginDate = new Date(Date.parse(date));
        const timeDifference = currentDate - lastLoginDate;

        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const millisecondsPerWeek = 7 * millisecondsPerDay;
        const millisecondsPerMonth = 30.44 * millisecondsPerDay; // Approximate average month length
        const millisecondsPerYear = 365.25 * millisecondsPerDay; // Approximate average year length

        if (timeDifference < millisecondsPerDay) {
            return 'Today';
        } else if (timeDifference < 7 * millisecondsPerDay) {
            const days = Math.floor(timeDifference / millisecondsPerDay);
            return days === 1 ? '1 day ago' : `${days} days ago`;
        } else if (timeDifference < 4 * millisecondsPerWeek) {
            const weeks = Math.floor(timeDifference / millisecondsPerWeek);
            return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
        } else if (timeDifference < 12 * millisecondsPerMonth) {
            const months = Math.floor(timeDifference / millisecondsPerMonth);
            return months === 1 ? '1 month ago' : `${months} months ago`;
        } else {
            const years = Math.floor(timeDifference / millisecondsPerYear);
            return years === 1 ? '1 year ago' : `${years} years ago`;
        }
    };

    useEffect(() => {
        if (!updatedEdited) return;
        getEditedDomainList();
    }, [domainChanges, getEditedDomainList, updatedEdited]);

    const handleUserUpdate = (user, key, value) => {
        setUserChanges({
            ...userChanges,
            [user.uid]: { ...userChanges[user.uid], [key]: value },
        });
    };

    useEffect(() => {
        if (!updatedEdited) return;
        setUserChanges({});
        setDomainChanges({});
    }, [editMode, setUserChanges, setDomainChanges, updatedEdited]);

    const handleDone = async (discardChanges) => {
        if (discardChanges === false) {
            let updatedUsersList = structuredClone(users);
            let updatedWhitelist = [...userWhitelist];
            let updatedDomainList = [...domains];
            Object.entries(userChanges).forEach(([uid, changes]) =>
                Object.entries(changes).forEach(([key, value]) => {
                    if (key === 'whitelisted') {
                        if (value === true) {
                            updatedWhitelist.push(uid);
                            addUserWhitelist(uid);
                        } else {
                            updatedWhitelist = updatedWhitelist.filter(
                                (userUid) => userUid !== uid
                            );
                            removeUserWhitelist(uid);
                        }
                    } else {
                        let updateIndex = users.findIndex(
                            (user) => user.uid === uid
                        );
                        updatedUsersList[updateIndex][key] = value;
                        setValue({ uid: uid }, updateUser, key, value);
                    }
                })
            );
            Object.entries(domainChanges).forEach(([domain, action]) => {
                if (action === 'add') {
                    updatedDomainList.push(domain);
                    addDomainWhitelist(domain);
                } else {
                    updatedDomainList = updatedDomainList.filter(
                        (domainName) => domainName !== domain
                    );
                    removeDomainWhitelist(domain);
                }
            });
            setUsers(updatedUsersList);
            setUserWhitelist(updatedWhitelist);
            setDomains(updatedDomainList);
        }
        setEditMode(false);
    };

    const handleAddDomain = async () => {
        if (!domainToAdd || domainToAdd === '') {
            console.log('Domain field is empty');
            return;
        }
        handleDomainUpdate(domainToAdd, 'add');
        setDomainToAdd(null);
        handleClose();
    };

    const handleDomainUpdate = (key, value) => {
        setDomainChanges({ ...domainChanges, [key]: value });
    };

    const handleRemoveDomain = async (domain) => {
        handleDomainUpdate(domain, 'remove');
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    if (!editedUsers || !editedWhitelist || !editedDomainList) return;

    return (
        <>
            <Header user={user} />
            <Container component='main' sx={{ mb: 10 }}>
                <Box
                    display='flex'
                    alignItems='center'
                    minHeight='100vh'
                    flexDirection='column'
                >
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 9 : 11}>
                            <Box sx={{ flex: '1 1 auto' }} />
                        </Grid>
                        <Grid item xs={1}>
                            {!editMode ? (
                                <Tooltip title='edit'>
                                    <Button
                                        onClick={() => {
                                            setEditMode(true);
                                        }}
                                        sx={{ ml: 2, mr: 0 }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <>
                                    <Tooltip title='done'>
                                        <Button
                                            onClick={() => {
                                                handleDone(false);
                                            }}
                                            sx={{ ml: 1, mr: 0 }}
                                        >
                                            <DoneIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title='discard changes'>
                                        <Button
                                            onClick={() => {
                                                handleDone(true);
                                            }}
                                            sx={{ ml: 0, mr: 0 }}
                                        >
                                            <CancelIcon />
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Card sx={{ width: 1, mb: 2 }}>
                        <CardContent>
                            <Typography component='h1' variant='h5'>
                                Users
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    stickyHeader
                                    aria-label='sticky table'
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align='right'>
                                                Email
                                            </TableCell>
                                            <TableCell align='right'>
                                                Whitelisted
                                            </TableCell>
                                            <TableCell align='right'>
                                                Admin
                                            </TableCell>
                                            <TableCell align='right'>
                                                Web Search
                                            </TableCell>
                                            <TableCell align='right'>
                                                Posts
                                            </TableCell>
                                            <TableCell align='right'>
                                                Posts Created
                                            </TableCell>
                                            <TableCell align='right'>
                                                Last Seen
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {editedUsers.map((user) => (
                                            <TableRow
                                                key={user.uid}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component='th'
                                                    scope='row'
                                                >
                                                    {user.name}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {user.email}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <Chip
                                                        label={
                                                            editedWhitelist.includes(
                                                                user.uid
                                                            )
                                                                ? 'true'
                                                                : 'false'
                                                        }
                                                        disabled={!editMode}
                                                        onClick={() =>
                                                            handleUserUpdate(
                                                                user,
                                                                'whitelisted',
                                                                !editedWhitelist.includes(
                                                                    user.uid
                                                                )
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <Chip
                                                        label={
                                                            user.isAdmin
                                                                ? 'true'
                                                                : 'false'
                                                        }
                                                        disabled={!editMode}
                                                        onClick={() =>
                                                            handleUserUpdate(
                                                                user,
                                                                'isAdmin',
                                                                !user.isAdmin
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <Chip
                                                        label={
                                                            user.allowWebSearch
                                                                ? 'true'
                                                                : 'false'
                                                        }
                                                        disabled={!editMode}
                                                        onClick={() =>
                                                            handleUserUpdate(
                                                                user,
                                                                'allowWebSearch',
                                                                !user.allowWebSearch
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {editMode ? (
                                                        <TextField
                                                            label='Posts'
                                                            type='number'
                                                            defaultValue={
                                                                user.posts
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onWheel={(e) =>
                                                                e.target.blur()
                                                            }
                                                            onChange={(e) =>
                                                                handleUserUpdate(
                                                                    user,
                                                                    'posts',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <>
                                                            {user.posts
                                                                ? user.posts
                                                                : 0}
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {editMode ? (
                                                        <TextField
                                                            label='Posts Created'
                                                            type='number'
                                                            defaultValue={
                                                                user.totalPostsCreated
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onWheel={(e) =>
                                                                e.target.blur()
                                                            }
                                                            onChange={(e) =>
                                                                handleUserUpdate(
                                                                    user,
                                                                    'totalPostsCreated',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <>
                                                            {user.totalPostsCreated
                                                                ? user.totalPostsCreated
                                                                : 0}
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {timeSinceLastLogin(
                                                        user.lastPostRefresh
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Box sx={{ flex: '1 1 auto' }} />
                        </Grid>
                        <Grid item xs={2}>
                            {editMode && (
                                <Tooltip title='edit'>
                                    <Button
                                        onClick={() => {
                                            setDialogOpen(true);
                                        }}
                                        sx={{ ml: 2, mr: 0 }}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                handleAddDomain();
                            }
                        }}
                    >
                        <DialogTitle>Add domain to whitelist</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                id='domain'
                                label='Domain'
                                fullWidth
                                variant='standard'
                                onChange={handleDomainToAdd}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleAddDomain} autoFocus>
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Card sx={{ width: 1 }}>
                        <CardContent>
                            <Typography component='h1' variant='h5'>
                                Domains
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    stickyHeader
                                    aria-label='sticky table'
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Domain</TableCell>
                                            <TableCell align='right'>
                                                Status
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {editedDomainList.map((domain) => (
                                            <TableRow
                                                key={domain}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component='th'
                                                    scope='row'
                                                >
                                                    {domain}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <Chip
                                                        label='remove'
                                                        disabled={!editMode}
                                                        onClick={() =>
                                                            handleRemoveDomain(
                                                                domain
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default Users;
